.availableList {
  background-color: transparent;
}

.selectedList {
  background-color: $gray-lighter;
}

.ddContainer {
  min-height:20em;
  height: 55vh;
  overflow-y: scroll;
  padding: 0.5em;
  border: 1px solid $gray-light;
}

.reportBuilderForm {
  display: none;
}

.showReportBuilderForms {
  .reportBuilderForm {
    display: block;
  }
}