.table-dashboard {
  tr {
    td:first-child {
      text-align: left;
      width: 55%;

      &.selected {
        font-weight: bold;
      }
    }

    td {
      text-align: center;
      width: 15%;
    }

    /* Add dashes to empty cells that could otherwise contain a number */
    td[title]:empty {
      position: relative;

      &:after {
        content: '-';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 5px;
      }
    }

    td:not(:first-child):not(:empty) {
      color: #FFF;
      font-weight: bold;

      a {
        color: #FFF;
      }

      &.db-danger {
        background: #CC0000;
      }

      &.db-warning {
        background: #ebaf00;
      }

      &.db-info {
        background: #00d407;
      }

      &.db-data {
        color: #000;

        a {
          color: #000;
        }
      }

      &.selected {
        color: #FFF !important;
        background-color: #000 !important;

        a {
          color: #FFF !important;
        }
      }
    }
  }
}

.out-of-date {
  opacity: 0.4;
}