$brand-primary: #2b6e8c;
$brand-success:         #08bf10;
$brand-info:            #5bc0de;
$brand-warning:         #f2da16;
$brand-danger:          #d81209;

$tab-overview: #cc2f00;
$tab-assessing: #db6600;
$tab-quotes: #e39e00;
$tab-reports: #76b80d;
$tab-emails: #007668;
$tab-notes: #006486;
$tab-tasks: #006486;
$tab-photos: #007cb5;
$tab-attachments: #465ab2;
$tab-tracking: #6d47b1;
$tab-invoices: #873b9c;

:root {
  --tab-overview: #{$tab-overview};
  --tab-assessing: #{$tab-assessing};
  --tab-quotes: #{$tab-quotes};
  --tab-reports: #{$tab-reports};
  --tab-emails: #{$tab-emails};
  --tab-notes: #{$tab-notes};
  --tab-tasks: #{$tab-tasks};
  --tab-photos: #{$tab-photos};
  --tab-attachments: #{$tab-attachments};
  --tab-tracking: #{$tab-tracking};
  --tab-invoices: #{$tab-invoices};
}

.tab-1 {
  background-color: $tab-overview;
}

.tab-2 {
  background-color: $tab-assessing;
}

.tab-3 {
  background-color: $tab-quotes;
}

.tab-4 {
  background-color: $tab-reports;
}

.tab-5 {
  background-color: $tab-emails;
}

.tab-6 {
  background-color: $tab-notes;
}

.tab-7 {
  background-color: $tab-photos;
}

.tab-8 {
  background-color: $tab-attachments;
}

.tab-9 {
  background-color: $tab-tracking;
}

.tab-10 {
  background-color: $tab-invoices;
}

$calendar-item-task: $tab-assessing;
$calendar-item-event: $tab-notes;
$calendar-item-inspection: $tab-quotes;
$calendar-item-repairs: $tab-photos;
$calendar-item-quote: $tab-emails;
$calendar-item-report: $tab-overview;

$tooltip-opacity: 1;

$border-radius-base:        2px;
$border-radius-large:       2px;
$border-radius-small:       2px;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-lg-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

$btn-task-color:              #fff;
$btn-task-bg:                 $calendar-item-task;
$btn-task-border:             darken($btn-task-bg, 5%);

$btn-event-color:              #fff;
$btn-event-bg:                 $calendar-item-event;
$btn-event-border:             darken($btn-event-bg, 5%);

$btn-inspection-color:              #fff;
$btn-inspection-bg:                 $calendar-item-inspection;
$btn-inspection-border:             darken($btn-inspection-bg, 5%);