#onlyofficeContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $navbar-inverse-bg;
}

#onlyofficeApp {
  width: 100%;
  height: calc(100vh - 50px);
}

#onlyofficePageFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2px 10px;

  .logo {
    height: 40px;
  }
}

#onlyofficeSaveAndCloseButton {
  float: right;
  margin-top: 5px;
}

#documentClosedMessage {
  display: none;
  position: absolute;
  top: 30vh;
  left: 0;
  right: 0;
  color: #eee;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}