#jobTracker {
  border-top: 1px solid $brand-primary;
  box-shadow: 0 2px 2px 2px $brand-primary;

  position: fixed;
  left: 0;
  right: 0;
  bottom: -47vh;

  width: 100%;
  z-index: 2040;
  height: 50vh;
  background: #FFF;

  transition: all 0.5s ease;

  &.tracker-visible {
    bottom: 0;
  }

  .tracker-header {
    line-height: 3vh;
    font-size: 2vh;
    text-align: center;
    cursor: pointer;
    background: $gray-lighter;
  }

  .tracker-list {
    padding-left: 1em;
    padding-right: 1em;
  }
}

#jobTracker-refreshButton {
  float: right;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  line-height: 3vh
}

#jobTrackerTable, #app_tracking_lines {
  .dtcol--job-ourReferenceId {
    width: 10%;
  }

  .dtcol--line-dateOfEvent {
    width: 20%;
  }

  .dtcol--line-description {
    width: 39%;
  }

  .dtcol--line-professionalTime {
    width: 7%;
  }

  .dtcol--line-adminTime {
    width: 7%;
  }

  .dtcol--line-travelDistance {
    width: 7%;
  }

  .dtcol--line-miscCost {
    width: 10%;
  }
}