#taskTabs {
  margin-top: 16px;
}

.todo-list-container {
  margin-top: 0;
  max-height: 225px;
  overflow-y: scroll;
  padding: 10px 5px;

  .todo-list-item {
    display: block;
    white-space: nowrap;
    overflow-x: hidden;
    padding: 2px 4px;
    margin: 2px 0;
    border-radius: 4px;
    color: #FFF;
  }
}

.listDone {
  text-align: center;
  font-size: 16px;
  padding: 10px;
}

.alldone {
  text-align: center;

  .catpic {
    max-width: 100%;
    max-height: 200px;
  }
}