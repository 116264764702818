.novoss-email-address-list {
  margin-bottom: 1em;
}

.novoss-email-address {
  display: block;

  &.novoss-email-address-to {

  }

  &.novoss-email-address-cc {
    color: #4f86c7;
  }

  &.novoss-email-address-bcc {
    color: #3ca46f;
  }
}

.novoss-email-body-container {
  .cke_chrome {
    box-shadow: none;
  }

  .cke_top {
    display: none;
  }

  // Hide the bar that allows resizing
  //.cke_bottom {
  //  display: none;
  //}
}

.listed-email-attachment {
  display: inline-block;
  margin-left: 1em;

  button {
    padding: 0.3em;
  }
}

.email-heading {
  padding: 2px 0px 2px 15px;
}

.glyphicon-email-in:before {
  color: #5bc0de;
  content: "\e114";
}

.glyphicon-email-out:before {
  color: #08bf10;
  content: "\e113";
}

.glyphicon-unstarred:before {
  color: $gray-lighter;
  -webkit-text-stroke: 1px $gray-light2;
  content: "\e006";
}

.glyphicon-starred-loading:before {
  color: #009dff !important;
  -webkit-text-stroke: 1px #0070f1 !important;
  content: "\e006";
}

.glyphicon-starred:before {
  color: #ffb700;
  -webkit-text-stroke: 1px #f1a300;
  content: "\e006";
}

.email-star {
  cursor: pointer;
}

.btn-email-delete {
  background-color: transparent;
  color: #d81209;
}

.token-input-dropdown li:hover .remove-email-option {
  visibility: visible;
}

.remove-email-option {
  visibility: hidden;
  color: #d81209;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.novoss-email-panel {
  padding: 4px;

  &:nth-child(odd) {
    background-color: $table-bg-accent;
  }

  .email-subject {
    top: 3px;
    padding-left: 4px;
  }
}

.email-options-toggle {
  color: $brand-primary;
  background-color: transparent;
}

.email-stats-container {
  margin-top: 1.5em;
  background-color: $table-bg-hover;
  padding: 0.7em;
  border-radius: 5px;
}

.email-stats-heading {
  font-weight: bold;
}

.email-fwd-info-tooltip {
  width: 10vw;
  height: 10vw;
  min-width: 10px;
  min-height: 10px;
  position: relative;
  overflow-x: visible;

  .tooltip-inner {
    position: absolute;
    text-align: left;
    white-space: nowrap;
    top: 0;
    left: 0;
    max-width: 80vw;
    overflow-x: hidden;
    padding: 10px;
    background-color: #FFF;
    color: #000;
    box-shadow: #000 1px 1px 3px;

    .forwarderAddress {
      font-weight: bold;
    }

    .forwardedDate {
      font-weight: bold;
    }
  }
}