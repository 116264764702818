.cams-sms-container {
  margin-bottom: 15px;
}

.cams-sms-header {
  overflow: auto;

  span {
    display: inline-block;
  }

  .sms-date {
    float: right
  }
}

.cams-sms-bubble {
  padding: 6px 6px 6px 9px;
  position: relative;
  background: $table-bg-accent;
  border-radius: .4em;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: $table-bg-accent;
    border-bottom: 0;
    border-left: 0;
    margin-left: -5px;
    margin-bottom: -10px;
  }
}

.cams-sms-footer {
  text-align: right;
  font-size: small;
}