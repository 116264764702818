// Claim and additional contact tags
.label-container {
  font-size: 14px;
  margin: 5px 0;

  .label {
    padding: 0.1em 0.4em 0.1em 0.4em;
  }

  .label-checkbox {
    display: none;
  }

  .label-checkbox + .label {
    user-select: none;
    cursor: pointer;
  }

  .label-checkbox:not(:checked) + .label {
    opacity: 0.5;
  }
}

.label-clickable {
  cursor: pointer;
}

// Add carets to additional contact tags with dropdown lists
.dropdown-tag {
  display: inline-block;
  cursor: pointer;
  > span.label:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 4px;
    vertical-align: middle;
    border-top:   $caret-width-base dashed;
    border-right: $caret-width-base solid transparent;
    border-left:  $caret-width-base solid transparent;
  }
}

// Allow a section to take up most of the width of the screen
.wide-boi {
  // Centering from https://stackoverflow.com/a/24895631/1151228
  background: #fff;
  width: 90vw;
  position: relative;
  left: calc(-45vw + 50%);
  padding: 1em;
  border: 1px $panel-default-border solid;
}

.label-priority-1 {
  background-color: $brand-success;
}

.label-priority-2 {
  background-color: $brand-info;
}

.label-priority-3 {
  background-color: $brand-warning;
}

.label-priority-4 {
  background-color: $brand-danger;
}

.color-swatch {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.claim-header-info {
  margin-bottom: 10px;
}

#fancyTabs {
  margin-top: 10px;
  border-bottom: none;

  > li {
    border: none;
    border-radius: 10px 10px 0 0;
    margin-right: 2px;
    margin-bottom: 0;

    &:not(.active) {
      box-shadow: inset 0px -2px 3px rgba(0, 0, 0, 0.5);
    }

    &.overflow-menu-tab a {
      line-height: 20px;
    }

    a {
      padding: 5px 12px;
      background-color: transparent;
      border: none;
      color: #ffffff;
    }

    a:hover {
      background-color: transparent;
      border: none;
    }

    &.active a {
      background-color: transparent;
      border: none;
      color: #ffffff;
      text-decoration: underline;
    }

    &.basic-info-tab-button {
      color: $brand-primary;
      box-shadow: none;

      &.active {
        color: $brand-primary;

        a {
          color: $brand-primary;
        }
      }

      a {
        color: $brand-primary;
      }
    }
  }
}

.fancy-tabs {
  border: solid #000;
  border-width: 4px 0 0 0;
}

@media (max-width: $screen-md-max) {
  #fancyTabs {
    li > a {
      padding: 5px 6px;
    }
  }
}

.panel-contrasting {
  > div.panel-heading {
    button.btn-link {
      color: #fff;
    }
  }
}

.panel-maintenance {
  > div.panel-heading {
    background-color: $tab-overview;
  }
}

.panel-quotes {
  > div.panel-heading {
    background-color: $tab-quotes;
  }
}

.panel-other-auths {
  > div.panel-heading {
    background-color: $tab-assessing;
  }
}

.panel-loss-of-rent {
  > div.panel-heading {
    background-color: $tab-emails;
  }
}

.panel-payments {
  > div.panel-heading {
    background-color: $tab-invoices;
  }
}

.send-appointment-container {
  margin-bottom: 5px;
}