.jq-upload-queue-styles {
  height: 10em;
  overflow-y: scroll;
  border: 1px solid $gray-light;
  position: relative;
  z-index: 1;
  margin-bottom: 1em;

  &:before {
    cursor: pointer;
    content: 'Drag files here';
    position: absolute;
    top: 0.3em;
    left: 0.3em;
    bottom: 0.3em;
    right: 0.3em;
    text-align: center;
    border: 3px dashed $gray-light;
    border-radius: 0.2em;
    padding: 0.5em;
    font-size: 2em;
    color: $gray-light;
    z-index: -1;
    opacity: 0.5;
  }

  &:hover:before {
    opacity: 0.8;
  }
}

.jq-upload-file-container {
  border: 1px solid $gray-lighter;
  margin: 0.5em;
  padding: 0.5em 0;
  background-color: #FFF;
}