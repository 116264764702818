@media (min-width: $grid-float-breakpoint) {
  #globalSearchInputWrapper {
    position: absolute;
    right: 0;
    display: inline-block;
    margin-top: -4px;
    margin-bottom: -8px;

    > ul {
      width: 300px;
      background-color: #555;
      color: #fff;
      border: none;
    }
  }
}

div.global-search-dropdown {
  max-height: 400px;

  ul {

    li {
      padding: 0;

      &.token-input-selected-dropdown-item {
        background-color: #ddd;
        color: inherit;
      }
    }
  }

  .gs-gui-search-link {
    display: block;
    padding: 5px 3px;
    font-size: 130%;
  }

  .gs-header-bar {
    padding: 3px;
    background-color: $brand-primary;
    font-size: 110%;
    color: #FFF;
  }

  .gs-result {
    display: block;
    color: #000;
    padding: 8px;
  }
}