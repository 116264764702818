.novoss-cke-no-gui {
  .cke_chrome {
    box-shadow: none;
  }

  .cke_top {
    display: none;
  }

  .cke_bottom {
    display: none;
  }
}