.reactiveDiv {
  position: relative;
}

.reactiveDiv.loading:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
}