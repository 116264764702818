.ncf-saved-flash {
  position: absolute;
  background: $alert-success-bg;
  border: $alert-success-border;
  color: $alert-success-text;
  text-align: center;
  padding: 1em;
  left: 0;
  right: 0;
  z-index: 999;
}

#customFormBuilderFieldContainer .novoss-sortable-entity {
  border: 1px dashed #888;
  padding: 0.5em;
  margin: 1em;
  background: #FFF;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2);
}

.ncf-preview-text-input {
  padding: 6px 12px;
  height: 34px;
  line-height: 22px;
  vertical-align: middle;
  background-color: #FFF;
  border: 1px solid #AAA;
  border-radius: 0;

  &.textarea {
    height: 68px;
  }
}

.ncf-preview-checkbox {
  padding: 6px 12px;
  height: 34px;
  line-height: 22px;
}

.ncf-inputish {
  line-height: 34px;
}

#fieldAdderButton {
  position: fixed;
  top: 30vh;
  right: 0;
  padding: 2em 1em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  box-shadow: -2px 0 2px 1px rgba(0,0,0,0.4);
}

.ncf-response-field-container {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.ncf-subformcollection-response-container {
  border: 1px solid $gray-lighter;
  padding: 0.5em;
  margin-bottom: 1em;
}

.ncf-subform-response-container {
  border: 2px solid $gray-lighter;
  border-right: 0;

  margin-left: 1em;
  padding-left: 0.5em;

  margin-bottom: 1em;
  padding-bottom: 0;
}

.ncf-html-field-container {
  border: 1px solid #84849e;
  padding: 0.5em;
  margin: 0.5em;

  p {
    margin-bottom: 0.2em;
  }

  .cke_chrome {
    box-shadow: none;
  }

  .cke_top {
    display: none;
  }

  .cke_bottom {
    display: none;
  }
}

.ncf-response-field-table {
  border-bottom: 1px solid #DDD;
  width: 100%;

  td {
    text-align: right;
  }
}

.ncf-inline-yes-no {
  div.radio {
    display: inline-block;
    margin: 0.25em 1em;
  }

  div.radio:nth-of-type(3) {
    float: right;
  }
}