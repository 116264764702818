#availableImageListSearch {
  border: 1px solid #999;
  margin-bottom: 5px;
}

.exigoScheduleImageList {
  height: 30em;
  border: 1px solid #999;
  margin-bottom: 1em;
  overflow-y: scroll;
}

.exigoScheduleImageBlock {
  position: relative;
  text-align: left;
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 0.5em;
  margin: 0.5em;

  &.selected {
    background-color: #dcdcff;
    border: 1px solid #9090ff;
  }

  .rotate-icon {
    position: absolute;
    top: 3px;
    left: 3px;
    color: $brand-primary;
    text-shadow: 1px 1px 1px #FFF;
    cursor: pointer;
  }

  .imageSchedule-imageData {
    max-height: 4em;
    overflow: hidden;
  }

  .imageSchedule-imageNumber {
    position: absolute;
    top: 23px;
    left: 0;
    z-index: 10;

    width: 2em;
    height: 1.5em;
    padding: 0.1em;

    background-color: #ccc;
    color: #222;
    border-top-right-radius: .3em;
    border-bottom-right-radius: .3em;

    text-align: center;
    font-weight: bold;
  }

  div.row {
    margin: 0;
  }

  .remove-icon {
    color: $alert-danger-text;
    text-align: right;
    padding: 0;
    cursor: pointer;
  }

  .image-container {
    padding: 0;
    height: 4em;
  }

  img {
    max-width: 100%;
    max-height: 4em;
  }

  .captionInput {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 3.5em;
    margin-top: 0.5em;
  }
}

.exigoPhotoSchedulePreview {
  .imageSchedule-outerContainer {
    position: relative;
    width: 100%;

    &.landscape:before {
      content: '';
      display: block;
      padding-top: 67%;
    }

    &.tall-portrait:before {
      content: '';
      display: block;
      padding-top: 133%;
    }

    .imageSchedule-innerContainer {
      position: absolute;
      //top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      img {
        border: 2px solid #000;
        width: 100%;

        &.landscape, &.tall-portrait {
          height: 100%;
        }
      }
    }
  }
}

.imageSchedule-hoverImage-tip {
  height: 100vh;
  max-width: 50vw;
  position: relative;
  display: inline-flex !important;
  align-items: center;

  .tooltip-inner {
    background-color: transparent;
    padding: 0;
    max-width: 1px;

    img {
      border: 2px solid $gray-dark;
      box-shadow: 1px 0 4px rgba(0, 0, 0, 0.5);
      max-width: 50vw;
    }
  }
}

.imageSchedule-hoverImage {

}