.novoss-address-autocomplete-container {
  position: relative;
}

.novoss-address-autocomplete-select {
  background: #FFF;
  border: 1px solid #ccc;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.6);
  position: absolute;
  width: 100%;
  max-height: 8em;
  overflow-y: scroll;
  z-index: 9999;
}

.novoss-address-autocomplete-select-item {
  background: #FFF;
  padding: 0.3em 0.5em;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background: #BDF;
  }
}

// Display the addressfinder results box on top of everything else.
// This fixes a bug where the results box was displayed below the unimodal.
ul.af_list {
  z-index: 99999 !important;
}