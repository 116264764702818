.novossQuickCollection-item {
  padding-top: $padding-large-vertical;
}

.novossQuickCollection-item + .novossQuickCollection-item {
  border: dotted $input-border;
  border-width: 1px 0 0 0;
}

.novossQuickCollection-smartHideLabels {
  .novossQuickCollection-item:not(:first-of-type) {
    label.control-label {
      display: none;
    }
  }
}