.table-multiline {
  > tbody {
    > tr > td {
      height: 3.5em;
      vertical-align: middle;
    }
  }
}

.dataTables_scrollHeadInner table {
  margin-bottom: 0;
}

// Column headings
table.dataTable {
  thead th {
    vertical-align: top !important;

    user-select: none;

    position: relative;

    &:after {
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: absolute;
      bottom: 2px;
      right: 2px;
    }

    &.sorting_desc:after {
      content: '\e114';
    }

    &.sorting_asc:after {
      content: '\e113';
    }

    &:hover:after {
      content: '\e113';
      opacity: 0.4;
    }

    &.sorting_asc:hover:after {
      content: '\e114';
      opacity: 0.4;
    }

    input, select {
      border-width: 1px;
    }
  }
}

// Processing overlay
div.dataTables_processing[style] {
  background: rgba(255,255,255,0.7) !important;
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  text-align: center;
  font-size: 2em;
}