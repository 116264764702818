.novossHorizontalCollection {
  .novossQuickCollection-itemContainer {
    border: 1px solid $gray-base;
    overflow-x: scroll;
    white-space: nowrap;
    vertical-align: top;
    @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
    padding: 0.5em;
    margin-bottom: 0.5em;

    .novossQuickCollection-item {
      vertical-align: top;
      white-space: normal;
      display: inline-block;
      position: relative;
      width: 90%;
      border: 1px solid $gray-light;
      margin: 0.5em 0.5em;
      padding: 0.5em 0.5em;
    }
  }
}