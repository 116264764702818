.calendarList {
  div.calendar-item {
    cursor: pointer;
    color: #fff;
    margin: 5px;
    border-radius: 5px;
    padding: 4px 2px 2px 2px;
    border: 1px solid transparent;

    &.completed {
      display: none;
      opacity: 0.5;

      .item-time, .item-title {
        text-decoration: line-through;
      }

      &.always-show {
        display: block;
      }
    }

    &.overdue-task {
      font-weight: bold;
    }

    &:hover {
      .item-time, .item-title {
        text-decoration: underline;
      }
    }
  }

  div.task {
    background-color: $calendar-item-task;
    border-color: darken($calendar-item-task, 10%);
  }

  div.event {
    background-color: $calendar-item-event;
    border-color: darken($calendar-item-event, 10%);
  }

  div.inspection {
    background-color: $calendar-item-inspection;
    border-color: darken($calendar-item-inspection, 10%);
  }

  div.repairs {
    background-color: $calendar-item-repairs;
    border-color: darken($calendar-item-repairs, 10%);
  }

  div.report {
    background-color: $calendar-item-report;
    border-color: darken($calendar-item-report, 10%);
  }

  div.quote {
    background-color: $calendar-item-quote;
    border-color: darken($calendar-item-quote, 10%);
  }

  div.item-options {
    text-align: right;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }
}