// Make the datepicker sit over all else.
.ui-datepicker {
  z-index: 2000 !important;
}

// Style quickCollection selectables
.quickCollection-email-attachment {
  padding: 3px;

  img {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid $gray-light2
  }
}

.novossQuickCollection-itemContainer.ui-selectable {
  .ui-selectee {
    border: 1px solid $input-border;
    margin-bottom: 5px;

    &.ui-selected {
      border: 1px solid $alert-info-border;
      background-color: $alert-info-bg;
    }
  }
}

// A button to clear the current selection
// It doesn't really belong here but I couldn't find a better place
#attachmentBulkEditOptions {
  display: none;
  margin: 0;
  padding: 10px;
}