ul.token-input-list {
  overflow: hidden;
  height: auto !important;
  min-height: $input-height-base;
  border: solid $input-border;
  border-width: 0 0 1px 0;
  cursor: text;
  font-size: $font-size-base;
  font-family: Verdana, sans-serif;
  z-index: 9999;
  margin: 0;
  padding: $padding-base-vertical*0.25 $padding-base-horizontal*0.5 0 $padding-base-horizontal*0.5;
  background-color: $input-bg;
  background-image: none;
  list-style-type: none;
  clear: left;

  display: block;
  width: 100%;
  line-height: $line-height-base;
  color: $input-color;
  border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
  @include transition(border-color ease-in-out .15s);

  // Customize the `:focus` state to imitate native WebKit styles.
  @include form-control-focus;

  // Placeholder
  @include placeholder;

  &.token-input-disabled {
    background-color: $input-bg-disabled;

    li input[type=text] {
      background-color: $input-bg-disabled;
    }
  }

  li {
    input {
      border: 0;
      width: 100px;
      padding: 3px 5px;
      background-color: transparent;
      margin: 0 0;
      -webkit-appearance: caret;
    }
  }

  &.has-error {
    background-color: $state-danger-bg;
    border: 1px solid $state-danger-text;
  }
}

li.token-input-token {
  overflow: hidden;
  height: auto !important;
  height: $font-size-base;
  margin: $padding-base-vertical*0.25 $padding-base-horizontal*0.25;
  padding: $padding-base-vertical*0.25 $padding-base-horizontal*0.25;
  background-color: #eff2f7;
  color: #000;
  cursor: default;
  border: 1px solid #ccd5e4;
  font-size: $font-size-base;
  font-family: Verdana, sans-serif;
  float: left;

  p {
    display: inline;
    padding: 0;
    margin: 0;
  }

  span {
    color: #a6b3cf;
    margin-left: 5px;
    font-weight: bold;
    cursor: pointer;
  }
}

li.token-input-selected-token {
  background-color: #5670a6;
  border: 1px solid #3b5998;
  color: #fff;
}

li.token-input-input-token {
  float: left;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  top: $padding-base-vertical*0.5;
}

li.token-input-insert-before {
  border-left: 1px solid #CC0000;
}

li.token-input-insert-after {
  border-right: 1px solid #CC0000;
}

div.token-input-dropdown {
  position: absolute;
  width: 400px;
  background-color: #fff;
  overflow: hidden;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  cursor: default;
  font-size: 11px;
  font-family: Verdana, sans-serif;
  z-index: 2000 !important; //Needs to be this high to get over bootstrap modals

  max-height: 150px;
  overflow-y: scroll;

  p {
    margin: 0;
    padding: 5px;
    font-weight: bold;
    color: #777;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      background-color: #fff;
      padding: 8px;
      margin: 0;
      list-style-type: none;

      &.token-input-dropdown-item {
        background-color: #fff;
      }

      &.token-input-dropdown-item2 {
        background-color: #fff;
      }

      &.token-input-selected-dropdown-item {
        background-color: #3b5998;
        color: #fff;
      }

      em {
        font-weight: bold;
        font-style: normal;
      }
    }
  }
}