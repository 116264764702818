.novossAjaxTab {
  &.loading {
    min-height: 10em;
    background-image: url("/images/css/fancy-loader.gif");
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: auto 80%;
  }

  &.saving {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0,0,0,0.5);
      z-index: 10;
    }
  }
}