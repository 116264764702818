.exigo-scope-set {
  > .panel {
    > .panel-collapse > .panel-body {
      padding: 0;
    }
  }

  .exigo-scope-item {
    > .panel {
      margin-top: 0;
      margin-bottom: 0;
      border-left: none;
      border-right: none;

      > .panel-heading {
        padding-top: 2px;
        padding-bottom: 2px;
      }

      > .panel-collapse > .panel-body {
        padding: 0;
      }
    }
  }
}