.novossDynamicBlock {
  position: relative;

  form {
    position: relative;
  }

  &.novossDynamicBlock-hasError {

    &:before {
      content: '';
      position: absolute;
      top: -0.5em;
      left: -0.5em;
      bottom: -0.5em;
      right: -0.5em;
      box-shadow: 0 0 0.25em rgba(200, 0, 0, 1);
      background-color: transparentize($state-danger-bg, 0.5);
    }
  }

  &.saving {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 10;
    }
  }

  .ndb-saving-indicator {
    position: fixed;
    bottom: 1em;
    right: 1em;
    height: 3em;
    font-size: 1em;
    line-height: 1em;
    padding: 0.5em 0.5em 0.5em 3.5em;
    white-space: nowrap;

    z-index: 999999;

    background-image: url("/images/css/square-loader2.gif");
    background-repeat: no-repeat;
    background-position: 5px 0;
    background-size: auto 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.unimodal-wide {
  min-width: 80vw;
}