/*
 * --Basics--
 */
@use "sass:math";

body {
  padding-top: 50px;
  overflow-y: scroll;
}

@media (min-width: $screen-sm-min) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: none;
    display: inline-block;
    margin: 0 -0.125em;
    vertical-align: top;
  }
}

@media (min-width: $screen-md-min) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: none;
    display: inline-block;
    margin: 0 -0.125em;
    vertical-align: top;
  }
}

@media (min-width: $screen-lg-min) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: none;
    display: inline-block;
    margin: 0 -0.125em;
    vertical-align: top;
  }
}

// Don't show link urls in print view
// http://stackoverflow.com/a/14931127/1151228
@media print {
  root, html, * {
    font-size: 12px !important;
  }

  h1, h1 * {
    font-size: 20px !important;
  }

  h2, h2 * {
    font-size: 18px !important;
  }

  h3, h3 * {
    font-size: 16px !important;
  }

  h4, h4 * {
    font-size: 14px !important;
  }

  a[href]:after {
    content: none !important;
  }
}

strong {
  font-weight: 600;
}

// Tables without the normal 'table' class should have 1px borders
//table:not(.table) {
//  border: 1px solid #ccc;
//
//  & tr, & td, & th {
//    border: 1px solid #ccc;
//  }
//}

thead {
  background: linear-gradient(0deg, rgb(87, 168, 202) 2%, rgba(255, 255, 255, 0) 10%);
}

th {
  font-weight: 600;
}

.label {
  font-weight: 500;
  font-size: 12px;
}

/*
 * Navbar
 */
.navbar-nav {
  width: 100%;
}

.navbar-menu-left {
  ul.dropdown-menu {
    left: auto;
    right: 0;
  }
}

@media (min-width: $grid-float-breakpoint) {
  .navbar-pull-right {
    float: right !important;
  }
}

//Make the header logo bigger. Normal nav links have 15px padding all round, making the max image height just 20px.
.navbar-nav .headerLogo {
  background-color: #FFFFFF;
  border-radius: 0 0 10px 10px;

  > a {
    padding-top: 2px;
    padding-bottom: 0;

    .camsLogo {
      height: 35px;
      padding: 8px;
    }

    .companyLogo {
      height: 35px;
      padding: 8px;
    }
  }
}

// Move the calendar menu and the add button closer together
.navbar-nav .calendar-main-button > a {
  padding-right: 0;
}

.navbar-nav .calendar-add-button > a {
  padding-left: 6px;
  padding-right: 15px;

  .calendar-add-button-text {
    display: none;
  }

  // Hide the dropdown menu caret because the '+' is the dropdown menu
  .caret {
    display: none;
  }
}

// Show the "Add Calendar Item" text when the menu collapses
@media (max-width: $grid-float-breakpoint-max) {
  .navbar-nav .calendar-add-button-text {
    display: inline-block !important;
  }
}

/*
 * Forms
 */
label {
  margin-bottom: 0;
  font-weight: 600;
}

.form-control {
  border-width: 0 0 1px 0;
  @include box-shadow(none);
}

textarea.form-control {
  border-width: 1px;
}

.input-group-addon {
  border-width: 0 0 1px 0;
  padding: $padding-base-vertical 0 $padding-base-vertical $padding-base-horizontal;
}

$xl-size-factor: 1.5;
$xl-pad-factor: 1.2;
.input-xl {
  height: $input-height-large * $xl-pad-factor;
  padding: $padding-large-vertical $padding-large-horizontal;
  font-size: $font-size-large * $xl-size-factor;
  line-height: $line-height-large * $xl-pad-factor;
  border-radius: $input-border-radius-large * $xl-size-factor;
}

div.checkbox {
  white-space: nowrap;
}

.radio,
.checkbox {
  margin-top: 0;
  margin-bottom: 0;
}

div.form-choice-inline-options {
  div.checkbox {
    display: inline-block;
    margin-right: 1.5em;
  }
}

div.novoss-fake-form-group-compact {
  margin-bottom: 0;

  > label {
    margin-bottom: 0;
  }

  > .novoss-fake-input {
    padding: math.div($padding-base-vertical, 3) $padding-base-horizontal;
    min-height: 0;
    line-height: $font-size-base;
  }
}

.control-label.required {
  position: relative;

  &:after {
    content: '*';
    color: $brand-primary;
    font-weight: 300;
    position: relative;
    top: -0.3em;
    right: -0.2em;
  }
}

.novossDynamicBlock-hasError {
  input, textarea {
    &:invalid {
      border-color: $state-danger-text;
      background-color: $state-danger-bg;
    }
  }
}

div.novoss-fake-input {
  // From bootstrap/_forms.scss .form-control
  display: block;
  width: 100%;
  min-height: $input-height-base;
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

/*
 * Panels
 */
.panel {
  margin-top: 20px;

  &.panel-compact {
    margin-top: 0;
  }
}

h2.panel-title > button.btn-link {
  font-weight: 600;
}

.novoss-panel-body-compact {
  padding: 5px;
}

.alert.alert-compact {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 0;
}

.alert-link {
  text-decoration: underline;
}

/*
 * Footer
 */
.novoss-spacer-footer {
  min-height: 5em;
}

/*
 * Utilities
 */
.container-fluid-inline {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.center-block {
  float: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.inline-block {
  display: inline-block;
}

.padding-none {
  padding: 0;
}

.padding-comfy {
  padding: 1em;
}

.padding-spacious {
  padding: 2em;
}

.row-no-padding {
  > [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.row-narrow-padding {
  > [class*="col-"] {
    padding-left: ($grid-gutter-width*0.25) !important;
    padding-right: ($grid-gutter-width*0.25) !important;
  }
}

.breaker {
  border-bottom: 2px solid $gray-lighter;
  margin: 1em;
}

.breaker-dark {
  border-bottom: 2px solid $gray;
  margin: 1em;
}

.btn-size-only {
  border: 1px solid transparent;
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  padding: $padding-base-vertical $padding-base-horizontal;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.btn-black {
  color: #FFF;
  font-weight: 600;
  background-color: #000;
  border-color: #000;
}

/*
 * Better looking file inputs
 * http://www.abeautifulsite.net/whipping-file-inputs-into-shape-with-bootstrap-3/
 *
 *  <span class="btn btn-default btn-file">
 *     Browse <input type="file">
 *  </span>
 */
.btn-file {
  position: relative;
  overflow: hidden;

  input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }
}

//Allow file inputs to be cleared. See js/novossCoreBundle/fileInputListener.js
.btn-file-clear {
  display: none;
}

.file-input-container {
  &.disabled {
    .btn {
      background-color: $input-bg-disabled;
      border-color: darken($input-bg-disabled, 20%);
    }
  }
}

/*
 * Allow glyphicons to be flipped
 */
.glyphicon {
  &.glyphicon-flip-x {
    transform: scaleX(-1);
  }
}

//A button with as little style as possible
.btn-nostyle {
  @include button-variant($btn-default-color, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
}

.novoss-banner-flash {
  position: absolute;
  text-align: center;
  padding: 1em;
  left: 0;
  right: 0;
  z-index: 999;
}

.novoss-banner-flash-success {
  background: $alert-success-bg;
  border: $alert-success-border;
  color: $alert-success-text;
}

.novoss-banner-flash-info {
  background: $alert-info-bg;
  border: $alert-info-border;
  color: $alert-info-text;
}

.novoss-banner-flash-warning {
  background: $alert-warning-bg;
  border: $alert-warning-border;
  color: $alert-warning-text;
}

.novoss-banner-flash-danger {
  background: $alert-danger-bg;
  border: $alert-danger-border;
  color: $alert-danger-text;
}

.form-group {
  // Show help block when you hover over a form group
  &:hover {
    .hover-help {
      display: inline-block;
    }
  }

  label {
    // Hide help blocks by default
    .hover-help {
      display: none;
    }

    // Show them when focussed on an input
    &:has(+ input:focus), &:has(+ select:focus) {
      .hover-help {
        display: inline-block;
      }
    }
  }
}

/**
 * Help blocks
 */
.hover-help {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 100%;
  padding: 0 8px;
  margin-left: 3px;
  font-size: small;
  text-align: left;
  background-color: $cams-highlight-blue;
  color: $cams-feature-blue;
  font-weight: normal;

  &:hover:after {
    content: attr(data-help);
    position: absolute;
    color: $cams-header-text-grey;
    font-style: italic;
    min-width: 200px;
    background: $cams-header-grey;
    padding: 3px 10px;
    margin-left: 12px;
    font-size: small;
    z-index: 2;
  }
}